// import { useContext, useEffect, useRef, useState } from 'react'
// import axios from '../../helpers/Axios'
// import s from './homepage.module.css'
// import carType from './homepageUtils/carstyle.json'
// import carBrands from './homepageUtils/carbrands.json'
// import { useNavigate } from 'react-router-dom'
// import { indFormat } from '../../helpers/IndCurrencyFormat'
// import { Context, ContextLogin } from '../../helpers/context'
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { aws_bucket_url, api_url } from '../../helpers/base_url'
// import { Link } from 'react-router-dom'
// export default function HomePage() {
//     const location = useContext(Context)
//     const [isLogged, setIsLogged] = useContext(ContextLogin)
//     //links
//     const homepageDataUrl = '/homepage_data'
//     localStorage.setItem('homePageValue', 'mainHome')
//     //refs
//     let tab0 = useRef()
//     let tab1 = useRef()
//     let tab2 = useRef()
//     let tab3 = useRef()
//     let filter = useRef()
//     let filterBtn = useRef()
//     const [tabs, setTabs] = useState({ tab1: false, tab2: false, tab3: false })
//     //navs
//     let nav = useNavigate()
//     //states
//     const [isChauffeurDriven, setIsChauffeurDriven] = useState(true)
//     const [isSelfDriven, setIsSelfDriven] = useState(false)
//     const [isAirportPickup, setIsAirportPickup] = useState(false)
//     const [selfDriveCars, setSelfDriveCars] = useState([])
//     const [chauffeur, setChauffeur] = useState([])
//     const [standard, setStandard] = useState([])
//     const [outstaion, setOutstation] = useState([])
//     const [airport, setAirport] = useState([])
//     const [selfDrive, setSelfDrive] = useState([])
//     const [loaded, setLoaded] = useState(false)
//     const [lowToHigh, setLowToHigh] = useState(false)
//     const [highToLow, setHighToLow] = useState(false)
//     const [priceRange, setPriceRange] = useState(5000)
//     const [adminpage, setAdminpage] = useState('')
//     //effects

//     useEffect(() => {
//         document.title = "Luxury Car Rentals";
//         const metaDescription = document.querySelector('meta[name="description"]');
//         if (metaDescription) {
//             metaDescription.setAttribute("content", "Welcome to Blck Luxury, the place where the best driving experience begins. Elevate your journey with Blck Luxury, where luxury has no limits.");
//         }
//     }, []);

//     useEffect(() => {
//         setLowToHigh(false)
//         setHighToLow(false)
//         // !isLogged && nav('/login') 
//         async function fetchData() {
//             if (localStorage['City'] === 'Dubai') {
//                 tab3.current.classList.add(`${s.selected}`)
//                 tab2.current.classList.remove(`${s.selected}`)
//                 tab1.current.classList.remove(`${s.selected}`)
//                 setTabs({ ...tabs, tab3: true })
//             }
//             else {
//                 tab1.current.classList.add(`${s.selected}`)
//                 tab2.current.classList.remove(`${s.selected}`)
//                 tab3.current.classList.remove(`${s.selected}`)
//                 setTabs({ ...tabs, tab1: true })
//             }
//             await axios.get(`/surge`)
//                 .then((res) => {
//                     setAdminpage(res.data.users)
//                     // console.log(adminpage[0], "^^^^^^^^^^^^")
//                 })
//                 .catch((err) => { console.log(err); })

//             await axios.get(`/get-selfdrive-cars?location=${location}`)
//                 .then((res) => { setSelfDriveCars(res.data) })
//                 .catch((err) => { console.log(err); })

//             await axios.get(`/get-chauffeur-cars?location=${location}`)
//                 .then((res) => {
//                     setChauffeur(res.data)
//                     setStandard(res.data.standard)
//                     setOutstation(res.data.outstaion)
//                     setAirport(res.data.airport)
//                 })
//                 .catch((err) => { console.log(err); })
//         }
//         fetchData();

//         setLoaded(true)

//         return () => { console.log('Cleaned'); }
//     }, [homepageDataUrl, location])

//     useEffect(() => {
//         if (localStorage['City'] === 'Dubai') {
//             setIsSelfDriven(true)
//             setIsAirportPickup(false)
//             setIsChauffeurDriven(false)
//         }
//         else {
//             setIsSelfDriven(false)
//             setIsAirportPickup(false)
//             setIsChauffeurDriven(true)
//         }

//     }, [location])

//     // function Onrequest(data){
//     //     const mm = data[21]
//     //     const ss=''
//     //                                         if (mm !== 0) {
//     //                                             ss=data[21]
//     //                                           return ss
//     //                                         }
//     //                                         else if(mm == 0) {
//     //                                             ss='OnRequest'
//     //                                             return ss
//     //                                         }
//     // }
//     function slideIn() {
//         try {
//             filter.current.children[0].classList.remove(`${s.slideOutLeft}`)
//             filter.current.children[0].classList.add(`${s.slideInLeft}`)
//             axios.get(`/get-chauffeur-cars?location=${location}`)
//             .then((res) => {
//                 setChauffeur(res.data)
//                 setStandard(res.data.standard)
//                 setOutstation(res.data.outstation)
//                 setAirport(res.data.airport)
//             })
//         } catch (error) { }
//         setTimeout(() => {
//             filter.current.style = 'display:block'
//         }, 200);
//     }

//     function slideOut() {
//         try {
//             filter.current.children[0].classList.remove(`${s.slideInLeft}`)
//             filter.current.children[0].classList.add(`${s.slideOutLeft}`)
//         } catch (error) { }
//         setTimeout(() => {
//             filter.current.style = 'display:none'
//         }, 200);
//     }
//     useEffect(() => {
//         AOS.init();
//         AOS.refresh();
//     }, []);
//     useEffect(() => {
//         if (lowToHigh == true) {
//             setChauffeur({ ...chauffeur, standard: standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1) })
//             // setChauffeur({...chauffeur,outstaion: outstaion.sort((a, b) => (a[21] > b[21]) ? 1 : -1),})
//             // setChauffeur({...chauffeur,airport: airport.sort((a, b) => (a[21] > b[21]) ? 1 : -1),})
//         }

//         // setChauffeur({ ...chauffeur, standard: chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1) })

//         if (highToLow)
//             setChauffeur({
//                 ...chauffeur, standard: chauffeur.standard.sort((a, b) => (a[21] < b[21]) ? 1 : -1),
//                 // outstaion: chauffeur.outstaion.sort((a, b) => (a[21] < b[21]) ? 1 : -1),
//                 // airport: chauffeur.airport.sort((a, b) => (a[21] < b[21]) ? 1 : -1)
//             })
//     }, [lowToHigh, highToLow])

//     function selectedPriceRange() {
//         setChauffeur({ ...chauffeur, standard: standard.filter((item) => item[21] < priceRange), })

//     }
//     // useEffect(()=>{

//     // },[highToLow])

//     // function filterPriceLowToHigh(){
//     //     let ordered = chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1);
//     //     if(lowToHigh)
//     //         setChauffeur({...chauffeur, standard:chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1)})
//     //     else setChauffeur({...chauffeur, standard:standard})
//     // }
//     // function filterPriceHighToLow(){
//     //     let ordered = chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1);
//     //     if(lowToHigh)
//     //         setChauffeur({...chauffeur, standard:chauffeur.standard.sort((a, b) => (a[21] < b[21]) ? 1 : -1)})
//     //     else setChauffeur({...chauffeur, standard:outstaion})
//     // }
//     // let lastElement = ordered.pop();
//     // ordered.unshift(lastElement);
//     // console.log(chauffeur.standard);

//     // console.log(carsData);
//     // console.log(chauffeurDrivern);
//     // console.log(selfDriven);
//     // console.log(airportPickupDrop);
//     // console.log(standard);
//     // console.log(airport);
//     // console.log(outstaion);
//     // console.log(selfDriveCars);
//     // console.log(chauffeur.standard);

//     // console.log(selfDrive)
//     // console.log(lowToHigh);
//     // console.log(highToLow);
//     function brands(x){
       
//         if(isChauffeurDriven){
//         setChauffeur({ ...chauffeur, standard: standard.filter((item) => (item[1].includes(x.split(' ')[0]))) })
//         }else if(isAirportPickup){
            
//             setChauffeur({ ...chauffeur, airport: airport.filter((item) => (item[1].includes(x.split(' ')[0]))) })
 
//         }else{
//             setChauffeur({ ...chauffeur, selfDrive: selfDrive.filter((item) => (item[1].includes(x.split(' ')[0]))) })

//         }
      
//     }
// //  console.log(chauffeur,'ff');
// const [showScrollButton, setShowScrollButton] = useState(false);
// const [backgroundColor, setBackgroundColor] = useState('#3D3C3C');

// useEffect(() => {
//   const handleScrollButtonVisibility = () => {
//     window.pageYOffset > 300 ? setShowScrollButton(true) : setShowScrollButton(false);
//   };

//   const handleBackgroundColorChange = () => {
//     const scrollY = window.pageYOffset;
//     const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
//     const scrollPercentage = scrollY / maxScroll;
    
//     // Interpolating from rgb(61, 60, 60) to rgb(0, 0, 0)
//     const startColor = { r: 61, g: 60, b: 60 };
//     const endColor = { r: 0, g: 0, b: 0 };
    
//     const r = Math.round(startColor.r + (endColor.r - startColor.r) * scrollPercentage);
//     const g = Math.round(startColor.g + (endColor.g - startColor.g) * scrollPercentage);
//     const b = Math.round(startColor.b + (endColor.b - startColor.b) * scrollPercentage);
    
//     setBackgroundColor(`rgb(${r}, ${g}, ${b})`);
//   };

//   window.addEventListener('scroll', handleScrollButtonVisibility);
//   window.addEventListener('scroll', handleBackgroundColorChange);

//   return () => {
//     window.removeEventListener('scroll', handleScrollButtonVisibility);
//     window.removeEventListener('scroll', handleBackgroundColorChange);
//   };
// }, []);

// const handleScrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: 'smooth' });
// };

    
//     return (
//         <main className={`${s.homepage}`} style={{ backgroundColor }}>
//             <div className={s.homepageElements}>
//                 <Carousel className={s.reactCarousel} autoPlay infiniteLoop showThumbs={false} showArrows={false} swipeable={true} stopOnHover={true} showStatus={false}>
//                     <Link to={"/"}> <div className={s.banner}>
//                         {/* <span className={s.bannerSpan}>Self Drive Rentals</span> */}
//                     </div></Link>
//                     <Link to={"/yachts"}><div className={s.banner1}>
//                         {/* <span className={s.bannerSpan}>Yachts</span> */}
//                     </div></Link>
//                     <Link to={"/jets_copters"}><div className={s.banner2}>
//                         {/* <span className={s.bannerSpan}>Private Jet Rentals</span> */}
//                     </div></Link>
//                 </Carousel>
//                 <div className={s.tabSection}>
               
//                     {localStorage['City'] != 'Dubai' ? (<>
//                         <div ref={tab1} className={`${s.tab}`}
//                             onClick={() => {
//                                 setTabs({ tab1: true, tab2: false, tab3: false })
//                                 tab2.current.classList.remove(`${s.selected}`)
//                                 tab3.current.classList.remove(`${s.selected}`)
//                                 tab1.current.classList.add(`${s.selected}`)
//                                 setIsChauffeurDriven(true)
//                                 setIsAirportPickup(false)
//                                 setIsSelfDriven(false)
//                             }}>Chauffeur Driven</div>
//                         <div ref={tab2} className={s.tab}
//                             onClick={() => {
//                                 setTabs({ tab1: false, tab2: true, tab3: false })
//                                 tab3.current.classList.remove(`${s.selected}`)
//                                 tab1.current.classList.remove(`${s.selected}`)
//                                 tab2.current.classList.add(`${s.selected}`)
//                                 setIsChauffeurDriven(false)
//                                 setIsAirportPickup(true)
//                                 setIsSelfDriven(false)
//                             }}>Airport Pickup/Drop</div>
//                         <div ref={tab3} className={s.tab}
//                             onClick={() => {
//                                 setTabs({ tab1: false, tab2: false, tab3: true })
//                                 tab1.current.classList.remove(`${s.selected}`)
//                                 tab2.current.classList.remove(`${s.selected}`)
//                                 tab3.current.classList.add(`${s.selected}`)
//                                 setIsChauffeurDriven(false)
//                                 setIsAirportPickup(false)
//                                 setIsSelfDriven(true)
//                             }}>Self-Drive</div></>) : (
//                         <>
                        
//                             <div ref={tab3} className={s.tab}
//                                 onClick={() => {
//                                     setTabs({ tab1: false, tab2: false, tab3: true })
//                                     tab1.current.classList.remove(`${s.selected}`)
//                                     tab2.current.classList.remove(`${s.selected}`)
//                                     tab3.current.classList.add(`${s.selected}`)
//                                     setIsChauffeurDriven(false)
//                                     setIsAirportPickup(false)
//                                     setIsSelfDriven(true)
//                                 }}>Self-Drive</div>
//                             <div ref={tab1} className={`${s.tab}`}
//                                 onClick={() => {
//                                     setTabs({ tab1: false, tab2: true, tab3: false })
//                                     tab3.current.classList.remove(`${s.selected}`)
//                                     tab1.current.classList.remove(`${s.selected}`)
//                                     tab2.current.classList.add(`${s.selected}`)
//                                     setIsChauffeurDriven(false)
//                                     setIsAirportPickup(true)
//                                     setIsSelfDriven(false)
//                                 }}>Airport Pickup/Drop</div>
//                             <div ref={tab2} className={s.tab}
//                                 onClick={() => {
//                                     setTabs({ tab1: true, tab2: false, tab3: false })
//                                     tab2.current.classList.remove(`${s.selected}`)
//                                     tab3.current.classList.remove(`${s.selected}`)
//                                     tab1.current.classList.add(`${s.selected}`)
//                                     setIsChauffeurDriven(true)
//                                     setIsAirportPickup(false)
//                                     setIsSelfDriven(false)
                                    
//                                 }}>Chauffeur Driven</div>

//                         </>)}
                    
//                         <button className={` ${s.filter}`} onClick={slideIn}>
//                         Show Filter
//                       </button>
                        
//                 </div>
//                 <div  >
//                 <button  className={s.mobilefilter} onClick={slideIn}>
//                 Show Filter
//               </button>
//                 </div>

//                 <div ref={filter} className={s.filterWindow}>

//                     <div className={s.filterElements}>

//                         <div className={s.filterHeader}>
//                             <button
//                                 type="button"
//                                 class="btn-close btn-close-white position-absolute top-0 end-0 mt-3 me-3"
//                                 onClick={slideOut}></button>

//                         </div>
//                         <div className={`${s.filterSortBy} ${s.filterElement}`}>
//                             <span className={s.filterHeaderText}>Sort By</span>
//                             <div className={s.checkbox}>
//                                 <label>
//                                     <input name='check' className={s.checkbox} type="radio"
//                                         onChange={() => {
//                                             setLowToHigh(false);
//                                             setHighToLow(false);
//                                             // setChauffeur({...chauffeur, standard:standard});
//                                         }} /> <span></span>
//                                 </label>
//                                 <span>Most Popular</span>
//                             </div>
//                             <div className={s.checkbox}>
//                                 <label>
//                                     <input name='check' className={s.checkbox} type="radio"
//                                         onChange={() => {
//                                             setLowToHigh(!lowToHigh);
//                                             setHighToLow(false)
//                                             // filterPriceLowToHigh();
//                                         }} /> <span></span>
//                                 </label>
//                                 <span className={s.checkboxNames}>Price - Low to High</span>
//                             </div>
//                             <div className={s.checkbox}>
//                                 <label>
//                                     <input name='check' className={s.checkbox} type="radio"
//                                         onChange={() => {
//                                             setHighToLow(!highToLow);
//                                             setLowToHigh(false)
//                                             // filterPriceHighToLow()
//                                         }} /> <span></span>
//                                 </label>
//                                 <span>Price - High to Low</span>
//                             </div>
//                         </div>
//                         <div className={`${s.filterPriceRange} ${s.filterElement}`}>
//                             <span className={s.filterHeaderText}>Price Range</span>
//                             <span className={s.priceRangeValue}><span>{indFormat.format(priceRange)}</span></span>
//                             <div className={s.slidecontainer}>
//                                 <input type="range" min="3000" max="100000" className={s.slider} id={s.myRange} value={priceRange}
//                                     onChange={(e) => {
//                                         setPriceRange(parseInt(e.target.value));
//                                         selectedPriceRange();
//                                     }} />
//                             </div>
//                         </div>
//                           <div className={`${s.carStyle} ${s.filterElement}`}>
//                             <span className={s.filterHeaderText}>Car Style</span>
//                             <div className={s.carTypeList}>
//                                 {
//                                     carType.data.map((data) => {
//                                         return (
//                                             <div key={data.id}>
//                                                 <img src={require(`../../assets/images/cartype/${data.image}`)} alt={`${data.name}`} />
//                                                 <span>{data.name}</span>
//                                             </div>
//                                         )
//                                     })
//                                 }
//                             </div>
//                         </div>
//                         <div className={`${s.carStyle} ${s.filterElement} ${s.carBrands}`}>
//                             <span className={s.filterHeaderText}>Car Brands</span>
//                             <div className={s.carTypeList}>
//                                {
//                                     carBrands.data.map((data) => {
//                                         return (
//                                             <div key={data.id}
//                                             onClick={() => {brands(data.name)}}>
//                                                 <img   src={require(`../../assets/images/carbrands/${data.image}`)} alt={`${data.name}`} />
//                                                 <span>{data.name}</span>
//                                             </div>
//                                         )
//                                     })
//                                 }
//                             </div>
//                         </div>
//                         <div className={`${s.results}`}>
//                             <button className={s.resultBtns}
//                                 onClick={() => {

//                                     axios.get(`/get-chauffeur-cars?location=${location}`)
//                                         .then((res) => {
//                                             setChauffeur(res.data)
//                                             setStandard(res.data.standard)
//                                             setOutstation(res.data.outstation)
//                                             setAirport(res.data.airport)
//                                         })

//                                         .catch((err) => { console.log(err); })
//                                 }}>Clear All</button>
//                             <button className={s.resultBtns} onClick={slideOut}>Show Results</button>
//                         </div>
//                     </div>
//                 </div>
//                 {selfDriveCars == false && isSelfDriven ?
//                     <p className={s.contant} >We are really sorry that we are unable to serve you this time, our team is working hard to build the Self Drive fleet in this location.</p>
//                     : <></>

//                 }
//                 {loaded ? <div className={s.bodySection}>
//                     {
//                         isChauffeurDriven && chauffeur.standard ? chauffeur.standard.map((data) => {
//                             const isConditionTrue = data[21];
//                             return (
//                                 <div data-aos="fade-up" className={s.card} key={data[0]}
//                                     onClick={() => {
//                                         const mm = data[21]

//                                         if (mm !== 0) {
//                                             nav(`/luxury-car-rental-in-${localStorage.City}/details-chauffeur-driven/${data[0]}`, localStorage["current_status"] = '1')
//                                         }
//                                         else if (mm == 0) {
//                                             nav(`/contactus`)
//                                         }
//                                     }
//                                     }
//                                 >
//                                     <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />

//                                     <div className={s.carDetails}>
//                                         <div className={s.carDetailsAlign}>
//                                             <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
//                                             <div className={s.rentDetails}>
//                                                 <div className={s.carRentPrice}>

//                                                   {isConditionTrue ? (
//                                                         <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_cd)}</p>
//                                                     ) : (
//                                                         <p>OnRequest</p>
//                                                     )}
//                                                 </div>
//                                                 <div className={s.carRange}>8 Hr 80Kms</div>
//                                             </div>
//                                         </div>
//                                         <div onClick={() => {
//                                             const mm = data[21]

//                                             if (mm !== 0) {
//                                                 nav(`/luxury-car-rental-in-${localStorage.City}/details-chauffeur-driven/${data[0]}`
//                                                     , localStorage["current_status"] = '1')
//                                             }
//                                             else if (mm == 0) {
//                                                 nav(`/contactus`)
//                                             }
//                                         }}>{isConditionTrue ? (
//                                             <button className={s.bookNowBtn}>Book Now</button>
//                                         ) : (
//                                             <button className={s.bookNowBtn}>Contact Us</button>
//                                         )}</div>
//                                     </div>
//                                     {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
//                                 </div>
//                             )
//                         }) : <></>
//                     }
//                     {/* {
//                         isChauffeurDriven && chauffeur.outstation ? chauffeur.outstation.map((data) => {
//                             return (
//                                 <div className={s.card} key={data[0]} onClick={() => {
//                                     nav(`/details/${data[0]}`)
//                                 }}>
//                                     <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />
//                                     <div className={s.carDetails}>
//                                         <div className={s.carDetailsAlign}>
//                                             <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
//                                             <div className={s.rentDetails}>
//                                                     <div className={s.carRentPrice}>
//                                                         {indFormat.format(data[21])}
//                                                     </div>
//                                                     <div className={s.carRange}>Standard</div>
//                                             </div>
//                                         </div>
//                                         <button className={s.bookNowBtn} onClick={() => {
//                                             nav(`/details/${data[0]}`)
//                                         }}>Book Now</button>
//                                     </div>
//                                     {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
//                                 </div>
//                             )
//                         }) : <></>
//                     } */}
//                     {
//                         isAirportPickup && chauffeur.airport ? chauffeur.airport.map((data) => {
//                             // console.log(data,'filter')
//                             const isConditionTrue = data[21];
//                             return (
//                                 <div data-aos="fade-up" className={s.card} key={data[0]} onClick={() => {
//                                     const mm = data[21]

//                                     if (mm !== 0) {
//                                         nav(`/luxury-car-rental-in-${localStorage.City}/details-airport-transfer/${data[0]}`,
//                                             localStorage["current_status"] = '3')
//                                     }
//                                     else if (mm == 0) {
//                                         nav(`/contactus`)
//                                     }
//                                 }
//                                 }>
//                                     <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />

//                                     <div className={s.carDetails}>
//                                         <div className={s.carDetailsAlign}>
//                                             <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
//                                             <div className={s.rentDetails}>
//                                                 <div className={s.carRentPrice}>
//                                                     {isConditionTrue ? (
//                                                         <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_at)}</p>
//                                                     ) : (
//                                                         <p>OnRequest</p>
//                                                     )}
//                                                 </div>
//                                                 <div className={s.carRange}>Airport Pickup/Drop</div>
//                                             </div>
//                                         </div>
//                                         <div onClick={() => {
//                                             const mm = data[21]
//                                             if (mm !== 0) {
//                                                 nav(`/luxury-car-rental-in-${localStorage.City}/details-airport-transfer/${data[0]}`,
//                                                     localStorage["current_status"] = '3')

//                                             }
//                                             else if (mm == 0) {
//                                                 nav(`/contactus`)
//                                             }
//                                         }}>{isConditionTrue ? (
//                                             <button className={s.bookNowBtn}>Book Now</button>
//                                         ) : (
//                                             <button className={s.bookNowBtn}>Contact Us</button>
//                                         )}</div>
//                                     </div>
//                                     {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
//                                 </div>
//                             )
//                         }) : <></>
//                     }
//                     {
//                         isSelfDriven && selfDriveCars ? selfDriveCars.map((data) => {
//                             const isConditionTrue = data[21];
//                             return (
//                                 <div data-aos="fade-up" className={s.card} key={data[0]} onClick={() => {
//                                     const mm = data[21]

//                                     if (mm !== 0) {
//                                         nav(`/self-drive-car-rental-in-${localStorage.City}/details-self-drive/${data[0]}`)
//                                     }
//                                     else if (mm == 0) {
//                                         nav(`/contactus`)
//                                     }
//                                 }
//                                 }>
//                                     <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />
//                                     <div className={s.carDetails}>
//                                         <div className={s.carDetailsAlign}>
//                                             <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
//                                             <div className={s.rentDetails}>
//                                                 <div className={s.carRentPrice}>

//                                                     {isConditionTrue ? (
//                                                         <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_sd)}</p>
//                                                     ) : (
//                                                         <p>OnRequest</p>
//                                                     )}
//                                                     {/* {indFormat.format(data[21])} */}
//                                                 </div>
//                                                 <div className={s.carRange}>Self Drive</div>
//                                             </div>
//                                         </div>
//                                         <div onClick={() => {
//                                             const mm = data[21]
//                                             if (mm !== 0) {
//                                                 nav(`/self-drive-rental-in-${localStorage.City}/details-self-drive/${data[0]}`)

//                                             }
//                                             else if (mm == 0) {
//                                                 nav(`/contactus`)
//                                             }
//                                         }}>{isConditionTrue ? (
//                                             <button className={s.bookNowBtn}>Book Now</button>
//                                         ) : (
//                                             <button className={s.bookNowBtn}>Contact Us</button>
//                                         )}</div>
//                                     </div>
//                                     {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
//                                 </div>
//                             )
//                         }) : <></>
//                     }
//                 </div> : <>Loading Cars...</>}
//             </div>
//         </main>
//     )
// } 
import { useContext, useEffect, useRef, useState } from 'react'
import axios from '../../helpers/Axios'
import s from './homepage.module.css'
import carType from './homepageUtils/carstyle.json'
import carBrands from './homepageUtils/carbrands.json'
import { useNavigate } from 'react-router-dom'
import { indFormat } from '../../helpers/IndCurrencyFormat'
import { Context, ContextLogin } from '../../helpers/context'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { aws_bucket_url, api_url } from '../../helpers/base_url'
import { Link } from 'react-router-dom'
import filterIcon from '../../assets/images/icons/filter-icon.png'; 
import Modal from './Modal';
import FAQ from '../nav-foot/FAQ'
import WBL from '../nav-foot/whyblckluxury'
// import x from '../nav-foot/nav_foo.module.css'
export default function HomePage() {
    const location = useContext(Context)
    const [isLogged, setIsLogged] = useContext(ContextLogin)
    //links
    const homepageDataUrl = '/homepage_data'
    localStorage.setItem('homePageValue', 'mainHome')
    //refs
    let tab0 = useRef()
    let tab1 = useRef()
    let tab2 = useRef()
    let tab3 = useRef()
    let filter = useRef()
    let filterBtn = useRef()
    const [tabs, setTabs] = useState({ tab1: false, tab2: false, tab3: false })
    //navs
    let nav = useNavigate()
    //states
    const [isChauffeurDriven, setIsChauffeurDriven] = useState(true)
    const [isSelfDriven, setIsSelfDriven] = useState(false)
    const [isAirportPickup, setIsAirportPickup] = useState(false)
    const [selfDriveCars, setSelfDriveCars] = useState([])
    const [chauffeur, setChauffeur] = useState([])
    const [standard, setStandard] = useState([])
    const [outstaion, setOutstation] = useState([])
    const [airport, setAirport] = useState([])
    const [selfDrive, setSelfDrive] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [lowToHigh, setLowToHigh] = useState(false)
    const [highToLow, setHighToLow] = useState(false)
    const [priceRange, setPriceRange] = useState(5000)
    const [adminpage, setAdminpage] = useState('')
    //effects
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        document.title = "Luxury Car Rentals";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "Welcome to Blck Luxury, the place where the best driving experience begins. Elevate your journey with Blck Luxury, where luxury has no limits.");
        }
    }, []);

    useEffect(() => {
        setLowToHigh(false)
        setHighToLow(false)
        // !isLogged && nav('/login') 
        async function fetchData() {
            if (localStorage['City'] === 'Dubai') {
                tab3.current.classList.add(`${s.selected}`)
                tab2.current.classList.remove(`${s.selected}`)
                tab1.current.classList.remove(`${s.selected}`)
                setTabs({ ...tabs, tab3: true })
            }
            else {
                tab1.current.classList.add(`${s.selected}`)
                tab2.current.classList.remove(`${s.selected}`)
                tab3.current.classList.remove(`${s.selected}`)
                setTabs({ ...tabs, tab1: true })
            }
            await axios.get(`/surge`)
                .then((res) => {
                    setAdminpage(res.data.users)
                    // console.log(adminpage[0], "^^^^^^^^^^^^")
                })
                .catch((err) => { console.log(err); })

            await axios.get(`/get-selfdrive-cars?location=${location}`)
                .then((res) => { setSelfDriveCars(res.data) })
                .catch((err) => { console.log(err); })

            await axios.get(`/get-chauffeur-cars?location=${location}`)
                .then((res) => {
                    setChauffeur(res.data)
                    setStandard(res.data.standard)
                    setOutstation(res.data.outstaion)
                    setAirport(res.data.airport)
                })
                .catch((err) => { console.log(err); })
        }
        fetchData();

        setLoaded(true)

        return () => { console.log('Cleaned'); }
    }, [homepageDataUrl, location])

    useEffect(() => {
        if (localStorage['City'] === 'Dubai') {
            setIsSelfDriven(true)
            setIsAirportPickup(false)
            setIsChauffeurDriven(false)
        }
        else {
            setIsSelfDriven(false)
            setIsAirportPickup(false)
            setIsChauffeurDriven(true)
        }

    }, [location])

    // function Onrequest(data){
    //     const mm = data[21]
    //     const ss=''
    //                                         if (mm !== 0) {
    //                                             ss=data[21]
    //                                           return ss
    //                                         }
    //                                         else if(mm == 0) {
    //                                             ss='OnRequest'
    //                                             return ss
    //                                         }
    // }
    function slideIn() {
        try {
            filter.current.children[0].classList.remove(`${s.slideOutLeft}`)
            filter.current.children[0].classList.add(`${s.slideInLeft}`)
            axios.get(`/get-chauffeur-cars?location=${location}`)
            .then((res) => {
                setChauffeur(res.data)
                setStandard(res.data.standard)
                setOutstation(res.data.outstation)
                setAirport(res.data.airport)
            })
        } catch (error) { }
        setTimeout(() => {
            filter.current.style = 'display:block'
        }, 200);
    }

    function slideOut() {
        try {
            filter.current.children[0].classList.remove(`${s.slideInLeft}`)
            filter.current.children[0].classList.add(`${s.slideOutLeft}`)
        } catch (error) { }
        setTimeout(() => {
            filter.current.style = 'display:none'
        }, 200);
    }
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    useEffect(() => {
        if (lowToHigh == true) {
            setChauffeur({ ...chauffeur, standard: standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1) })
            // setChauffeur({...chauffeur,outstaion: outstaion.sort((a, b) => (a[21] > b[21]) ? 1 : -1),})
            // setChauffeur({...chauffeur,airport: airport.sort((a, b) => (a[21] > b[21]) ? 1 : -1),})
        }

        // setChauffeur({ ...chauffeur, standard: chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1) })

        if (highToLow)
            setChauffeur({
                ...chauffeur, standard: chauffeur.standard.sort((a, b) => (a[21] < b[21]) ? 1 : -1),
                // outstaion: chauffeur.outstaion.sort((a, b) => (a[21] < b[21]) ? 1 : -1),
                // airport: chauffeur.airport.sort((a, b) => (a[21] < b[21]) ? 1 : -1)
            })
    }, [lowToHigh, highToLow])

    function selectedPriceRange() {
        setChauffeur({ ...chauffeur, standard: standard.filter((item) => item[21] < priceRange), })

    }
    // useEffect(()=>{

    // },[highToLow])

    // function filterPriceLowToHigh(){
    //     let ordered = chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1);
    //     if(lowToHigh)
    //         setChauffeur({...chauffeur, standard:chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1)})
    //     else setChauffeur({...chauffeur, standard:standard})
    // }
    // function filterPriceHighToLow(){
    //     let ordered = chauffeur.standard.sort((a, b) => (a[21] > b[21]) ? 1 : -1);
    //     if(lowToHigh)
    //         setChauffeur({...chauffeur, standard:chauffeur.standard.sort((a, b) => (a[21] < b[21]) ? 1 : -1)})
    //     else setChauffeur({...chauffeur, standard:outstaion})
    // }
    // let lastElement = ordered.pop();
    // ordered.unshift(lastElement);
    // console.log(chauffeur.standard);

    // console.log(carsData);
    // console.log(chauffeurDrivern);
    // console.log(selfDriven);
    // console.log(airportPickupDrop);
    // console.log(standard);
    // console.log(airport);
    // console.log(outstaion);
    // console.log(selfDriveCars);
    // console.log(chauffeur.standard);

    // console.log(selfDrive)
    // console.log(lowToHigh);
    // console.log(highToLow);
    function brands(x){
       
        if(isChauffeurDriven){
        setChauffeur({ ...chauffeur, standard: standard.filter((item) => (item[1].includes(x.split(' ')[0]))) })
        }else if(isAirportPickup){
            
            setChauffeur({ ...chauffeur, airport: airport.filter((item) => (item[1].includes(x.split(' ')[0]))) })
 
        }else{
            setChauffeur({ ...chauffeur, selfDrive: selfDrive.filter((item) => (item[1].includes(x.split(' ')[0]))) })

        }
      
    }
//  console.log(chauffeur,'ff');
useEffect(() => {
    // Check if the modal has already been shown
    const hasShownModal = localStorage.getItem('hasShownModal');
    if (!hasShownModal) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    // Set localStorage item to indicate the modal has been shown
    sessionStorage.setItem('hasShownModal', 'true');
  };
    return (
        <main className={`${s.homepage}`}>
        <Modal show={showModal} onClose={handleCloseModal} />
            <div className={s.homepageElements}>
                <Carousel className={s.reactCarousel} autoPlay infiniteLoop showThumbs={false} showArrows={false} swipeable={true} stopOnHover={true} showStatus={false}>
                    <Link to={"/"}> <div className={s.banner}>
                        {/* <span className={s.bannerSpan}>Self Drive Rentals</span> */}
                    </div></Link>
                    <Link to={"/yachts"}><div className={s.banner1}>
                        {/* <span className={s.bannerSpan}>Yachts</span> */}
                    </div></Link>
                    <Link to={"/jets_copters"}><div className={s.banner2}>
                        {/* <span className={s.bannerSpan}>Private Jet Rentals</span> */}
                    </div></Link>
                </Carousel>
                <div className={s.tabSection}>
               
                    {localStorage['City'] != 'Dubai' ? (<>
                        <div ref={tab1} className={`${s.tab}`}
                            onClick={() => {
                                setTabs({ tab1: true, tab2: false, tab3: false })
                                tab2.current.classList.remove(`${s.selected}`)
                                tab3.current.classList.remove(`${s.selected}`)
                                tab1.current.classList.add(`${s.selected}`)
                                setIsChauffeurDriven(true)
                                setIsAirportPickup(false)
                                setIsSelfDriven(false)
                            }}>Chauffeur Driven</div>
                        <div ref={tab2} className={s.tab}
                            onClick={() => {
                                setTabs({ tab1: false, tab2: true, tab3: false })
                                tab3.current.classList.remove(`${s.selected}`)
                                tab1.current.classList.remove(`${s.selected}`)
                                tab2.current.classList.add(`${s.selected}`)
                                setIsChauffeurDriven(false)
                                setIsAirportPickup(true)
                                setIsSelfDriven(false)
                            }}>Airport Pickup/Drop</div>
                        <div ref={tab3} className={s.tab}
                            onClick={() => {
                                setTabs({ tab1: false, tab2: false, tab3: true })
                                tab1.current.classList.remove(`${s.selected}`)
                                tab2.current.classList.remove(`${s.selected}`)
                                tab3.current.classList.add(`${s.selected}`)
                                setIsChauffeurDriven(false)
                                setIsAirportPickup(false)
                                setIsSelfDriven(true)
                            }}>Self-Drive</div></>) : (
                        <>
                        
                            <div ref={tab3} className={s.tab}
                                onClick={() => {
                                    setTabs({ tab1: false, tab2: false, tab3: true })
                                    tab1.current.classList.remove(`${s.selected}`)
                                    tab2.current.classList.remove(`${s.selected}`)
                                    tab3.current.classList.add(`${s.selected}`)
                                    setIsChauffeurDriven(false)
                                    setIsAirportPickup(false)
                                    setIsSelfDriven(true)
                                }}>Self-Drive</div>
                            <div ref={tab1} className={`${s.tab}`}
                                onClick={() => {
                                    setTabs({ tab1: false, tab2: true, tab3: false })
                                    tab3.current.classList.remove(`${s.selected}`)
                                    tab1.current.classList.remove(`${s.selected}`)
                                    tab2.current.classList.add(`${s.selected}`)
                                    setIsChauffeurDriven(false)
                                    setIsAirportPickup(true)
                                    setIsSelfDriven(false)
                                }}>Airport Pickup/Drop</div>
                            <div ref={tab2} className={s.tab}
                                onClick={() => {
                                    setTabs({ tab1: true, tab2: false, tab3: false })
                                    tab2.current.classList.remove(`${s.selected}`)
                                    tab3.current.classList.remove(`${s.selected}`)
                                    tab1.current.classList.add(`${s.selected}`)
                                    setIsChauffeurDriven(true)
                                    setIsAirportPickup(false)
                                    setIsSelfDriven(false)
                                    
                                }}>Chauffeur Driven</div>

                        </>)}
                    
                        <button className={` ${s.filter}`} onClick={slideIn}>
                        Show Filter
                      </button>
                        
                </div>
                <div  >
                <button  className={s.mobilefilter} onClick={slideIn}>
                <img src={filterIcon} alt="Show Filter" />   
                </button>
                </div>

                <div ref={filter} className={s.filterWindow}>

                    <div className={s.filterElements}>

                        <div className={s.filterHeader}>
                            <button
                                type="button"
                                class="btn-close btn-close-white position-absolute top-0 end-0 mt-3 me-3"
                                onClick={slideOut}></button>

                        </div>
                        <div className={`${s.filterSortBy} ${s.filterElement}`}>
                            <span className={s.filterHeaderText}>Sort By</span>
                            <div className={s.checkbox}>
                                <label>
                                    <input name='check' className={s.checkbox} type="radio"
                                        onChange={() => {
                                            setLowToHigh(false);
                                            setHighToLow(false);
                                            // setChauffeur({...chauffeur, standard:standard});
                                        }} /> <span></span>
                                </label>
                                <span>Most Popular</span>
                            </div>
                            <div className={s.checkbox}>
                                <label>
                                    <input name='check' className={s.checkbox} type="radio"
                                        onChange={() => {
                                            setLowToHigh(!lowToHigh);
                                            setHighToLow(false)
                                            // filterPriceLowToHigh();
                                        }} /> <span></span>
                                </label>
                                <span className={s.checkboxNames}>Price - Low to High</span>
                            </div>
                            <div className={s.checkbox}>
                                <label>
                                    <input name='check' className={s.checkbox} type="radio"
                                        onChange={() => {
                                            setHighToLow(!highToLow);
                                            setLowToHigh(false)
                                            // filterPriceHighToLow()
                                        }} /> <span></span>
                                </label>
                                <span>Price - High to Low</span>
                            </div>
                        </div>
                        <div className={`${s.filterPriceRange} ${s.filterElement}`}>
                            <span className={s.filterHeaderText}>Price Range</span>
                            <span className={s.priceRangeValue}><span>{indFormat.format(priceRange)}</span></span>
                            <div className={s.slidecontainer}>
                                <input type="range" min="3000" max="100000" className={s.slider} id={s.myRange} value={priceRange}
                                    onChange={(e) => {
                                        setPriceRange(parseInt(e.target.value));
                                        selectedPriceRange();
                                    }} />
                            </div>
                        </div>
                          <div className={`${s.carStyle} ${s.filterElement}`}>
                            <span className={s.filterHeaderText}>Car Style</span>
                            <div className={s.carTypeList}>
                                {
                                    carType.data.map((data) => {
                                        return (
                                            <div key={data.id}>
                                                <img src={require(`../../assets/images/cartype/${data.image}`)} alt={`${data.name}`} />
                                                <span>{data.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={`${s.carStyle} ${s.filterElement} ${s.carBrands}`}>
                            <span className={s.filterHeaderText}>Car Brands</span>
                            <div className={s.carTypeList}>
                               {
                                    carBrands.data.map((data) => {
                                        return (
                                            <div key={data.id}
                                            onClick={() => {brands(data.name)}}>
                                                <img   src={require(`../../assets/images/carbrands/${data.image}`)} alt={`${data.name}`} />
                                                <span>{data.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={`${s.results}`}>
                            <button className={s.resultBtns}
                                onClick={() => {

                                    axios.get(`/get-chauffeur-cars?location=${location}`)
                                        .then((res) => {
                                            setChauffeur(res.data)
                                            setStandard(res.data.standard)
                                            setOutstation(res.data.outstation)
                                            setAirport(res.data.airport)
                                        })

                                        .catch((err) => { console.log(err); })
                                }}>Clear All</button>
                            <button className={s.resultBtns} onClick={slideOut}>Show Results</button>
                        </div>
                    </div>
                </div>
                {selfDriveCars == false && isSelfDriven ?
                    <p className={s.contant} >We are really sorry that we are unable to serve you this time, our team is working hard to build the Self Drive fleet in this location.</p>
                    : <></>

                }
                {loaded ? <div className={s.bodySection}>
                    {
                        isChauffeurDriven && chauffeur.standard ? chauffeur.standard.map((data) => {
                            const isConditionTrue = data[21];
                            return (
                                <div data-aos="fade-up" className={s.card} key={data[0]}
                                    onClick={() => {
                                        const mm = data[21]

                                        if (mm !== 0) {
                                            nav(`/luxury-car-rental-in-${localStorage.City}/details-chauffeur-driven/${data[0]}`, localStorage["current_status"] = '1')
                                        }
                                        else if (mm == 0) {
                                            nav(`/contactus`)
                                        }
                                    }
                                    }
                                >
                                    <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />

                                    <div className={s.carDetails}>
                                        <div className={s.carDetailsAlign}>
                                            <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
                                            <div className={s.rentDetails}>
                                                <div className={s.carRentPrice}>

                                                  {isConditionTrue ? (
                                                        <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_cd)}</p>
                                                    ) : (
                                                        <p>OnRequest</p>
                                                    )}
                                                </div>
                                                <div className={s.carRange}>8 Hr 80Kms</div>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            const mm = data[21]

                                            if (mm !== 0) {
                                                nav(`/luxury-car-rental-in-${localStorage.City}/details-chauffeur-driven/${data[0]}`
                                                    , localStorage["current_status"] = '1')
                                            }
                                            else if (mm == 0) {
                                                nav(`/contactus`)
                                            }
                                        }}>{isConditionTrue ? (
                                            <button className={s.bookNowBtn}>Book Now</button>
                                        ) : (
                                            <button className={s.bookNowBtn}>Contact Us</button>
                                        )}</div>
                                    </div>
                                    {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
                                </div>
                            )
                        }) : <></>
                    }
                    {/* {
                        isChauffeurDriven && chauffeur.outstation ? chauffeur.outstation.map((data) => {
                            return (
                                <div className={s.card} key={data[0]} onClick={() => {
                                    nav(`/details/${data[0]}`)
                                }}>
                                    <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />
                                    <div className={s.carDetails}>
                                        <div className={s.carDetailsAlign}>
                                            <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
                                            <div className={s.rentDetails}>
                                                    <div className={s.carRentPrice}>
                                                        {indFormat.format(data[21])}
                                                    </div>
                                                    <div className={s.carRange}>Standard</div>
                                            </div>
                                        </div>
                                        <button className={s.bookNowBtn} onClick={() => {
                                            nav(`/details/${data[0]}`)
                                        }}>Book Now</button>
                                    </div>
                                    {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
                                </div>
                            )
                        }) : <></>
                    } */}
                    {
                        isAirportPickup && chauffeur.airport ? chauffeur.airport.map((data) => {
                            // console.log(data,'filter')
                            const isConditionTrue = data[21];
                            return (
                                <div data-aos="fade-up" className={s.card} key={data[0]} onClick={() => {
                                    const mm = data[21]

                                    if (mm !== 0) {
                                        nav(`/luxury-car-rental-in-${localStorage.City}/details-airport-transfer/${data[0]}`,
                                            localStorage["current_status"] = '3')
                                    }
                                    else if (mm == 0) {
                                        nav(`/contactus`)
                                    }
                                }
                                }>
                                    <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />

                                    <div className={s.carDetails}>
                                        <div className={s.carDetailsAlign}>
                                            <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
                                            <div className={s.rentDetails}>
                                                <div className={s.carRentPrice}>
                                                    {isConditionTrue ? (
                                                        <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_at)}</p>
                                                    ) : (
                                                        <p>OnRequest</p>
                                                    )}
                                                </div>
                                                <div className={s.carRange}>Airport Pickup/Drop</div>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            const mm = data[21]
                                            if (mm !== 0) {
                                                nav(`/luxury-car-rental-in-${localStorage.City}/details-airport-transfer/${data[0]}`,
                                                    localStorage["current_status"] = '3')

                                            }
                                            else if (mm == 0) {
                                                nav(`/contactus`)
                                            }
                                        }}>{isConditionTrue ? (
                                            <button className={s.bookNowBtn}>Book Now</button>
                                        ) : (
                                            <button className={s.bookNowBtn}>Contact Us</button>
                                        )}</div>
                                    </div>
                                    {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
                                </div>
                            )
                        }) : <></>
                    }
                    {
                        isSelfDriven && selfDriveCars ? selfDriveCars.map((data) => {
                            const isConditionTrue = data[21];
                            return (
                                <div data-aos="fade-up" className={s.card} key={data[0]} onClick={() => {
                                    const mm = data[21]

                                    if (mm !== 0) {
                                        nav(`/self-drive-car-rental-in-${localStorage.City}/details-self-drive/${data[0]}`)
                                    }
                                    else if (mm == 0) {
                                        nav(`/contactus`)
                                    }
                                }
                                }>
                                    <img src={`${aws_bucket_url}/${data[8]}`} alt="Car" />
                                    <div className={s.carDetails}>
                                        <div className={s.carDetailsAlign}>
                                            <div className={s.carName}>{data[1]}{' '}{data[2]}</div>
                                            <div className={s.rentDetails}>
                                                <div className={s.carRentPrice}>

                                                    {isConditionTrue ? (
                                                        <p>{indFormat.format((data[21]) + ((data[21]) / 100) * adminpage[0].standard_sd)}</p>
                                                    ) : (
                                                        <p>OnRequest</p>
                                                    )}
                                                    {/* {indFormat.format(data[21])} */}
                                                </div>
                                                <div className={s.carRange}>Self Drive</div>
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            const mm = data[21]
                                            if (mm !== 0) {
                                                nav(`/self-drive-rental-in-${localStorage.City}/details-self-drive/${data[0]}`)

                                            }
                                            else if (mm == 0) {
                                                nav(`/contactus`)
                                            }
                                        }}>{isConditionTrue ? (
                                            <button className={s.bookNowBtn}>Book Now</button>
                                        ) : (
                                            <button className={s.bookNowBtn}>Contact Us</button>
                                        )}</div>
                                    </div>
                                    {data[16] <= 0 ? <span className={s.soldout}><span>SOLD OUT</span></span> : <></>}
                                </div>
                            )
                        }) : <></>
                    }
                </div> : <>Loading Cars...</>}
            </div>
            
        <div className={`${s.flexcontainer1}`}>
        <WBL/>
        </div>
        <div className={`${s.flexcontainer1}`}>
        <FAQ/>
        </div>
        </main>
    )
} 