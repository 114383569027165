import React,{useEffect, useState, useRef} from 'react';
import Typography from '@mui/material/Typography';
import s from './blog.module.css'; // Import the CSS module

// Import temporary images
import img1 from '../../../assets/blog/pexels-zachtheshoota-2568539.jpg';
import img2 from '../../../assets/blog/pexels-sidesimagery-3556384.jpg';
import img3 from '../../../assets/blog/pexels-pixabay-164634.jpg';
import img4 from '../../../assets/blog/pexels-oidonnyboy-3751391.jpg';
import img5 from '../../../assets/blog/pexels-lynxexotics-3764984.jpg';
import img6 from '../../../assets/blog/pexels-kelly-1179532-3065609.jpg';
import img7 from '../../../assets/blog/pexels-bertellifotografia-3007436.jpg';
import img8 from '../../../assets/blog/pexels-mikebirdy-192501.jpg';
import img9 from '../../../assets/blog/pexels-zenzazione-2922140.jpg';

const Blog = () => {
  const links = [
    { href: '/Gear_Up_for_Adventure_Exploring_Blck_Luxury', text: 'Gear Up for Adventure: Exploring Blck Luxury', img: img1 },
    { href: '/Beyond_the_Ordinary_Unveiling_the_Glamour_of_Luxury_Car', text: 'Beyond the Ordinary: Unveiling the Glamour of Luxury Car', img: img2 },
    { href: '/Rev_Up_Your_Travels_How_Blck_Luxury_Makes_Every_Mile_Count', text: 'Rev Up Your Travels: How Blck Luxury Makes Every Mile Count', img: img3 },
    { href: '/Take_your_dreams_back_on_the_road_with_Go_Blck', text: 'Take Your Dreams Back on the Road with Go Blck', img: img4 },
    { href: '/Car_Rental_Bangalore_Find_the_Perfect_Luxury_Vehicl_for_Your_Needs_blog', text: 'Car Rental Bangalore: Find the Perfect Luxury Vehicle for Your Needs', img: img5 },
    { href: '/Explore_the_Ultimate_Luxury_Car_Rental_Experience_in_Bangalore_blog', text: 'Explore the Ultimate Luxury Car Rental Experience in Bangalore', img: img6 },
    { href: '/Cruise_into_Comfort_Where_Every_Drive_Tells_a_Story', text: 'Cruise into Comfort: Where Every Drive Tells a Story', img: img7 },
    { href: '/Experience_the_Pinnacle_of_Elegance_with_Blck_Luxury_Car_Rentals_in_Dubai', text: 'Experience the Pinnacle of Elegance with Blck Luxury Car Rentals in Dubai', img: img8 },
    { href: '/Class_and_Comfort_Luxury_Car_Rentals_Redefined_for_You', text: 'Class and Comfort: Luxury Car Rentals Redefined for You', img: img9 },
  ];
  useEffect(() => {
    document.title = "Luxury Car Rentals in India | Blck Luxury | News and Blog";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute("content", "At Blck Luxury, we prioritize your satisfaction and aim to provide the highest level of personalized service.");
    }
}, []);

  return (
    <div className={s.feed_main}>
      <Typography
        className={s.feed_title}
        variant="h2"
        sx={{
          marginBottom: 2,
          // backgroundImage: `url(${img1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '10px',
          color: '#fff',
          fontSize: '2.5rem'
        }}
      >
        &nbsp;My Blog
      </Typography>
      <Typography
        className={s.feed_description}
        variant="body1"
        sx={{
          marginBottom: 4,
          color: '#666',
          fontSize: '2rem'
        }}
      >
      </Typography>
      <div className={s.feed_list}>
        {links.map((link, index) => (
          <div key={index} className={s.feed_item}>
            <img src={link.img} alt="Blog" className={s.feed_image} />
            <div className={s.feed_content}>
              <Typography variant="h6" className={s.feed_text}>
                {`${index + 1}. ${link.text}`}
              </Typography>
              <Typography variant="body2" color="textSecondary" className={s.feed_date}>
                June 12, 2024 | Lifestyle
              </Typography>
              <Typography variant="body2" className={s.feed_description}>
              </Typography>
              <a href={link.href} className={s.read_more}>
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
