import React, { useContext, useEffect, useState } from 'react'
import s from './loginpage.module.css'
import c from '../../assets/css/custom.module.css'
import { auth } from './firebase.config';
// import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { ToastContainer, toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ContextLogin, UserId, PrevUrl } from '../../helpers/context'
import axios from '../../helpers/Axios';
import axiosInstance1 from 'axios';
import { json_server_url } from '../../helpers/base_url';
// export const user1 = 


function LoginPage() {
  const [isLogged, setIsLogged] = useContext(ContextLogin)
  const [userId, setUserId] = useContext(UserId)
  const [prevUrl, setPrevUrl] = useContext(PrevUrl)
 
  //useStates
  const [showotp, setShowotp] = useState(false)
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const[contact,setContact]=useState("")
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [clean, setClean] = useState([])
  const [randomOTP, setRandomOTP] = useState("");
  const [recive, setRecive] = useState([]);
  const[data,setData] = useState({phone: "", email: "",firstname: "",lastname:"", id:""});


  let nav = useNavigate()


  async function otpauth(){
   
    axios
    .get(`/user_information?contact=${contact}`)
    .then((res) => {
      setRecive(res.data.user);
      console.log(res.data.user[0]);
      setData({...data,phone: res.data.user[0].contact,
        email: res.data.user[0].email,
        firstname: res.data.user[0].firstname,
        lastname: res.data.user[0].lastname,
        id: res.data.user[0].id});
      })
    function generateOTP() {
      const digits = '0123456789';
      let otp = '';
      for (let i = 0; i < 6; i++) {
        otp += digits[Math.floor(Math.random() * 10)];
      }
      return otp;
    }
    
    // Example usage
    const randomOTP = generateOTP();
   if(contact){ setShowotp(!showotp)}
      setRandomOTP(randomOTP)
      // console.log(contact,'########');
      // console.log(randomOTP,'########');
      // console.log(otp,'########');
      //otp send with api  
    //  axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Customer OTP verfication for Blck luxury!, Your otp is ${randomOTP} Happy driving with Blck Luxury!.&MobileNumbers=91${contact}`)
    axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Thank you for choosing Blck Luxury. Your OTP for login ${randomOTP}. We are excited to have you on board!.&MobileNumbers=91${contact}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)

   
    toast.success('Sms send...')
    
    }
  const [userData, setUserData] = useState(null)
  // console.log(ph);
  // console.log(userData);
  // console.log(data);
  // useEffect(() => {
  //   axios.get(`/users?phone=${ph}`)
  //     .then((res) => { setUserData(res.data); })
  //     .catch((err) => { console.log(err); })
  // }, [ph])



  // useEffect(()=>{
  //   localStorage["USER_PHONE_NO"]=data.contact  
  // },[])

  // function postLoginData() {
  //   for (let i = 0; i < clean.length; i++) {
  //     try {
  //       axios.delete(`${json_server_url}/login/${clean[i].id}`)
  //     } catch (error) {
  //       console.log('not deleted');
  //     }
  //   }
  //   axios.post(`${json_server_url}/login`, {...data, auth:true})
  //     .then(() => { nav(prevUrl) })
  //     .catch((err) => { console.log(err); })
  // }
  
  function otpcheck(){

    // 
    if(otp){
    // console.log(randomOTP,'########');
    // console.log(otp,'########');
    if (randomOTP==otp){
    // console.log('done')
  
          if(data.id){  
            localStorage["USER_ID"]=data.id
            localStorage["USER_firstname"]=data.firstname
            localStorage["USER_email"]=data.email
            localStorage["USER_lastname"]=data.lastname
            localStorage["USER_PHONE_NO"]=data.phone
            toast.success('Login Successful')
            let webpage=sessionStorage.getItem("url1")  
            if(webpage){
              window.open(webpage)
            }else{
              nav('/navbar',{userdetails:data})
            }
             

            // nav('/homepage',{state:data})
           }
           else{
            toast.error("Please Create Account")

           }

          console.log("received user details ");
        }
    else{
      toast.error("OTP is wrong")
    }
  }
  else{ toast.error("Plese enter OTP")}
    
      }
  
  // const showToastMessageempty = () => {
  //   toast.error("Please Fill All Details", {
  //     position: toast.POSITION.TOP_CENTER,
  //     toastId: "1",
  //   });
  // };
  // const smsdone = () => {
  //   toast('What!', { closeable: true });
  // };
  // console.log(data.phone,"8888888888888888888888888")

  return (
    <div className={`${s.logingSection}`}>
      <Toaster />
      <main className={`${s.container}`}>
        <div className={`${c.bigText} ${c.bold}`}>Login to your account</div>
        <div className={`${s.phoneInput}`}>
          <span className={`${c.smallText}`}>Enter mobile number</span>
          <div className={`${s.inputs}`}>
            <input className={`${s.input1}`} value={'+91'} />
            <input value={contact} onChange={(e) => {
              setContact(e.target.value);
              // axios.get(`/getusers?phone=${e.target.value}`)
              //   .then((res) => { setUserData(res.data.user[0]); setData({...data, userId:res.data.user[0].id}) })
              //   .catch((err) => { console.log(err); })
            }} country={"in"} className={`${s.input2}`} type="text" name="phone" placeholder='Enter Mobile Number' />
          </div>
        </div>
        <div className={`${s.bottomSection}`}>
          <button onClick={otpauth} className={`${c.primaryBtnFilled}`}>Send OTP</button>
          {
            showotp ?
              <div className={`${s.otpinputs}`}>
                <OtpInput defaultValue={""} value={otp} onChange={setOtp} type="text" name="otp" OTPLength={6} otpType="number" />
              </div>
              : <></>
          }
          <button onClick={otpcheck} className={`${c.primaryBtnFilled}`}>Login</button>
          <div className={`${c.smallText} ${c.center}`} onClick={() => { nav('/signup') }} style={{ cursor: 'pointer' }}><u>No account? Create one</u></div>
        </div>
      </main>
    </div>
  )
}

export default LoginPage