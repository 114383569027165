import React from 'react';
import './modal.css'; // Make sure to import the CSS file

const Modal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = () => {
    console.log('Overlay clicked');
    onClose();
  };

  const handleImageClick = (e) => {
    e.stopPropagation(); // Prevent the overlay click event from triggering
    const couponCode = "INDIA@BLCK"; // Replace with your actual coupon code
    navigator.clipboard.writeText(couponCode).then(() => {
      alert("Coupon code copied to clipboard!");
      onClose();
    }, () => {
      alert("Failed to copy the coupon code.");
    });
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <img 
          src={require('../../assets/images/common/popimage2.jpg')} 
          alt="Coupon" 
          onClick={handleImageClick} 
        />
        <h2>Click the Image to Copy the Coupon Code</h2>
      </div>
    </div>
  );
};

export default Modal;
