import React from 'react';
import './WhyBlckLuxury.css';

const WhyBlckLuxury = () => {
  return (
    <div className="why-blck-luxury">
      <h2 style={{color:'white'}}>Why Blck Luxury?</h2>
      <p>
        At Blck Luxury, we redefine what it means to travel in style. Here’s why discerning clients like you choose us:
      </p>
      <p>
        <strong>Exquisite Fleet Selection:</strong> We pride ourselves on curating a fleet that includes the most sought-after luxury vehicles on the market. From high-performance luxury brands like the Rolls Royce and Bentley to the ultimate in executive elegance with Mercedes, BMW, and Audi, every car is chosen for its prestige, power, and presence.
      </p>
      <p>
        <strong>Unparalleled Customer Service:</strong> Our commitment to excellence starts the moment you enquire or book with us. Our team of experts ensures that every detail of your rental experience is tailored to your specific needs. Need a vehicle delivered to your doorstep or hotel? Special requests for your journey? We’ve got you covered.
      </p>
      <p>
        <strong>Ultimate Flexibility:</strong> Life doesn’t always stick to a schedule, and neither do we. Whether you need a car for a few hours to impress at a meeting, a weekend getaway, or an extended business trip, our flexible rental periods are designed to accommodate your needs without any hassle.
      </p>
      <p>
        <strong>Prestige & Sophistication:</strong> At Blck Luxury, we understand that your car is an extension of your personal brand. Arrive in one of our vehicles, and you’re not just arriving—you’re making a statement. Our cars are meticulously detailed and maintained to ensure they are always in pristine condition, so you can be sure that you’re driving nothing less than perfection.
      </p>
      <p>
        <strong>Distinctive Experience:</strong> We’re not just in the business of renting cars; we’re in the business of creating unforgettable experiences. Whether you’re celebrating a special occasion, planning a surprise, or simply want to enjoy the thrill of driving a luxury car, Blck Luxury ensures your experience is nothing short of extraordinary.
      </p>
      <p>
        Choose Blck Luxury, where every journey is a statement of class, power, and sophistication. Elevate your drive—because you deserve nothing but the best.
      </p>
    </div>
  );
}

export default WhyBlckLuxury;
