// import React, { useEffect, useState, useContext } from 'react'
// import s from './summary.module.css'
// import c from '../../assets/css/custom.module.css'
// import { indFormat } from '../../helpers/IndCurrencyFormat'
// import axios from '../../helpers/Axios'
// import feesData from '../../fees.json'
// import { aws_bucket_url, api_url, json_server_url } from '../../helpers/base_url'
// import { Context, ContextLogin, PrevUrl } from '../../helpers/context'
// import { Link, useLocation, useNavigate } from 'react-router-dom'
// function Summary() {
//   let nav = useNavigate()
//   const [isLogged, setIsLogged] = useContext(ContextLogin)
//   const [prevUrl, setPrevUrl] = useContext(PrevUrl)
//   const [loaded, setLoaded] = useState(false)
//   const [priceData, setPriceData] = useState()
//   const [finalPrice, setFinalPrice] = useState()
//   const [gst, setGst] = useState()
//   const [Gstin, setGstin] = useState(false)
//   const [agreedToTerms, setAgreedToTerms] = useState(false)
//   const [user, setUser] = useState([])
//   const [userData, setUserData] = useState([])
//   let location = useLocation()
//   useEffect(() => {

//     axios.get(`${json_server_url}/login`)
//     .then((res)=>{
//       setUser(res.data[0])
//       axios.get(`/getusers?id=${res.data[0].userId}`)
//       .then((res)=>{setUserData(res.data.user[0])})
//       .catch((err)=>{console.log(err);})
//     })
//     .catch((err)=>{console.log(err);})

//     axios.get(`${json_server_url}/summary`)
//       .then((res) => {
//         setPriceData(res.data[0])
//         let gst = parseInt(((
//           res.data[0].totalPrice +
//           (res.data[0].additionalKms * (res.data[0].totalPrice * feesData.fees[0].extraKms)) +
//           (res.data[0].additionalHrs * (res.data[0].totalPrice * feesData.fees[0].extraHrs)) +
//           res.data[0].allowance
//         ) * feesData.fees[0].gstChauffeur));
//         let price = parseInt(
//           (
//             res.data[0].totalPrice +
//             (res.data[0].additionalKms * (res.data[0].totalPrice * feesData.fees[0].extraKms)) +
//             (res.data[0].additionalHrs * (res.data[0].totalPrice * feesData.fees[0].extraHrs)) +
//             res.data[0].allowance
//           )
//         )
//         setGst(parseInt(gst))
//         setFinalPrice(parseInt(
//           gst + price
//         ))
//       })
//       .then(() => { setLoaded(true) })
//       .catch((err) => {
//         console.log(err);
//       })
//   }, [])

//   // console.log(priceData);
//   // console.log(finalPrice);
//   // console.log(calci);
//   // console.log(data);
//   // console.log(fees);
//   console.log(user);
//   console.log(userData);

//   return (
//     loaded ?
//       <main className={s.summaryMain}>
//         <div className={s.leftSide}>
//           <div className={`${s.summarySection}`}>
//             <div className={`${s.summaryHeader}`}>
//               <span className={`${c.bigText}`}>Summary</span>
//               <span className={`${c.smallText}`}>Booking details</span>
//             </div>
//             <div className={`${s.carDetailsSection}`}>
//               <img src={`${aws_bucket_url}/${priceData.img}`} alt="carImage" />
//               <div>
//                 <span className={`${c.bigText} ${s.bigText}`}>{priceData.name}</span>
//                 <span className={`${c.smallText}`}>{priceData.date || 'Mar 30, 2023'} &emsp; {priceData.time || '11:15AM'}</span>
//                 <span className={`${c.smallText}`}>Standard - 8Hr 80Kms</span>
//               </div>
//             </div>
//           </div>
//           <div className={`${s.personalInfoSection}`}>
//             <span className={`${c.bigText}`}>Personal Information</span>
//             <div className={`${s.infoForm}`}>
//               <div className={`${c.split}`}>
//                 <input type="text" name='firstName' placeholder='First Name' />
//                 <input type="text" name='mobileNumber' placeholder='Mobile Number' />
//               </div>
//               <input type="text" name='email' placeholder='Email' />
//               <textarea name="pickUpAddress" cols="30" rows="3" placeholder='Pick up Address'></textarea>
//               <textarea name="dropOffAddress" cols="30" rows="3" placeholder='Drop Off Address (Optional)'></textarea>
//               <textarea name="specialInstructions" cols="30" rows="3" placeholder='Mention any special instructions.'></textarea>
//               <div className={`${s.paragraphSection}`}>
//                 <span className={`${c.tinyText}`}>*   Any cancellation/modification to be informed only via email between 9am to 7pm on all working days and minimum 4 hours prior to the scheduled timer directly</span>
//                 <span className={`${c.tinyText}`}>*   Any cancellation under 24hrs of the scheduled time will attract 100% charges standard cancellation charges until last 24hrs of scheduled time will be 25%</span>
//                 <span className={`${c.tinyText}`}>*   Payment Gateway charges are not refunded on any platforms for any type of booking cancellations</span>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className={`${s.rightSide}`}>
//           <div className={`${s.rightSideHeader}`}>
//             <span className={`${c.extraSmallText}`}>Price Breakup</span>
//             <span className={`${c.mediumText}`}>Chauffeur Driven</span>
//           </div>
//           <div className={`${s.priceDetails}`}>
//             <div>
//               <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Base Price</span>
//               <span className={`${c.extraSmallText}`}>{indFormat.format(priceData.totalPrice)}</span>
//             </div>
//             <div>
//               <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Driver Allowance</span>
//               <span className={`${c.extraSmallText}`}>{indFormat.format(priceData.allowance)}</span>
//             </div>
//             <div>
//               <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Extra Km ({priceData.additionalKms})</span>
//               <span className={`${c.extraSmallText}`}>{indFormat.format(priceData.additionalKms * (priceData.totalPrice * feesData.fees[0].extraKms))}</span>
//             </div>
//             <div>
//               <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Extra Hr ({priceData.additionalHrs})</span>
//               <span className={`${c.extraSmallText}`}>{indFormat.format(priceData.additionalHrs * (priceData.totalPrice * feesData.fees[0].extraHrs))}</span>
//             </div>
//             <div>
//               <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>GST @12%</span>
//               <span className={`${c.smallText}`} style={{ color: 'white' }}>
//                 {indFormat.format(gst)}
//               </span>
//             </div>
//           </div>
//           <div className={`${s.totalPrice}`}>
//             <div>
//               <input id={s.totalPriceInput} type="text" placeholder='Enter Coupon Code' />
//               <button className={c.continueBtnFilled}>Apply</button>
//             </div>
//             <div className={s.total}>
//               <span className={c.bigText}>Total</span>
//               {loaded ? <span className={`${c.bigText} ${c.secondaryColor}`}>
//                 {indFormat.format(finalPrice)}
//               </span> : <>...</>}
//             </div>
//           </div>
//           <div className={s.readSection}>
//             <span>*   Convenience charges will be added based on mode of payment</span>
//             <span>*   Wedding package will attract additional charges</span>
//             <span>*   In case of further additional Hours / Kms you can pay your chauffeur directly</span>
//             <span>*   GST applicable on all our services, if you have a GSTIN you can quote the same below.</span>
//           </div>
//           <div className={s.payButtonSection}>
//             <span className={s.gstSection}>
//               <label>
//                 <input type="checkbox" onChange={(e) => { setGstin(!Gstin) }} />
//               </label>
//               <div className={c.extraSmallText}>GSTIN</div>
//             </span>
//             {Gstin ?
//               <div>
//                 <input id={s.totalPriceInput} style={{ height: '40px' }} type="text" name='gstNumber' placeholder='Enter GST Number' />
//                 <input id={s.totalPriceInput} style={{ height: '40px' }} type="text" name='companyName' placeholder='Enter Company Name' />
//               </div> : <></>}
//             <span>
//               <label>
//                 <input type="checkbox" onChange={() => { setAgreedToTerms(!agreedToTerms) }} />
//               </label>
//               <span className={c.extraSmallText}>I agree to the <span className={c.secondaryColor}><a target={'_blank'} href={'/terms-and-conditions'}>Terms & Conditions</a></span></span>
//             </span>
//             <div className={`${c.split} ${s.buttons}`}>
//               <button className={c.continueBtnFilled}
//                 onClick={() => {
//                   if (!agreedToTerms) alert('Please agree with our terms and Conditions.');
//                   else {
//                     if (user.auth == true) {
//                       alert('Payment Success!')
//                     }
//                     else {
//                       setPrevUrl(location.pathname)
//                       nav('/login')
//                     }
//                   }
//                 }}>Pay Now</button>
//               <button className={c.primaryBtnRed}>Edit</button>
//             </div>
//           </div>
//         </div>
//       </main> : <>Loading...</>
//   )
// }
// export default Summary

import React, { useEffect,useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import s from './summary.module.css'
import c from '../../assets/css/custom.module.css'
import { indFormat } from '../../helpers/IndCurrencyFormat'
import axios from 'axios'
import feesData from '../../fees.json'
import { aws_bucket_url, api_url, json_server_url } from '../../helpers/base_url'
import { useLocation, useNavigate } from "react-router";
import emailjs from "@emailjs/browser";
import { alertClasses } from '@mui/material'
import CryptoJS from 'crypto-js'
function SummaryOutstation() {
  let coupontext = useRef()
  const [loaded, setLoaded] = useState(true)
  const [priceData, setPriceData] = useState({})
  const [Gstin, setGstin] = useState(false)
  const[gstno,setGstno]=useState('')
  const[gstname,setGstname]=useState('')
  const [totalPrice, setTotalPrice] = useState()
  let navigate = useNavigate()
  let menu = useRef();
  let location = useLocation()
  let validnumber = useRef()
  let validemail = useRef()
  let validaddress = useRef()
  let validname = useRef()
  const [state, setState] = useState({});
  const [fName, setfName] = useState('');
  const [lName, setlName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [fullN, setfullN] = useState('');
  const [pNum, setpNum] = useState('');
  const [eMail, seteMail] = useState('');
  const[coupon,setCoupon]= useState('');
  const[inputcoupon,setInputcoupon] =useState('')
  const[couponvalue,setCouponvalue]=useState('')
  const[url,setUrl]=useState('')
  const[id_booking,setId_booking]=useState('')
  const[contactnumber,setContact]=useState('')
  let couponfound = useRef()
  const[parsedData,setparsedData]=useState('')
  useEffect(() => {
    const urlString = window.location.href
    setUrl(urlString)
    const queryRegex = /summary\?(.*)/; // Matches the entire query string after the '?'

    const matches = urlString.match(queryRegex);


    if (matches[1] && matches[1].length > 1) {
      const queryString = matches[1];
      const keyValuePairs = queryString.split('&');
      const parsedDataa = {};
      keyValuePairs.forEach(pair => {
        const secretKey = process.env.REACT_APP_API_KEY
        const [key, value] = pair.split('=');
        const decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(key), 'secretKey').toString(CryptoJS.enc.Utf8);
        const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(value), 'secretKey').toString(CryptoJS.enc.Utf8);
        const parsedValue = /^[0-9]+(\.[0-9]*)?$/.test(decryptedData1) ? parseFloat(decryptedData1) : decodeURIComponent(decryptedData1);
        parsedDataa[decryptedData] = parsedValue;
      });
      setparsedData(parsedDataa)
    } else {
      console.log("No valid query string found.");
    }
  }, [])
  useEffect(() => {

    if(localStorage.USER_firstname){
    localStorage["FullNAME"]=localStorage.USER_firstname+" "+localStorage.USER_lastname
    setfName(localStorage.USER_firstname)
    setPhone( localStorage.USER_PHONE_NO)
    setEmail(localStorage.USER_email)
    
    }
   
  })
 
  useEffect(() => {

    async function gettingData() {
      await axios.get(`${json_server_url}/outStation`)
        .then((res) => {
          setPriceData(res.data[0])
          setLoaded(true)
          setTotalPrice(parseInt(
            (
              res.data[0].price +
              res.data[0].allowance +
              (res.data[0].extraKm * (res.data[0].stdPrice * feesData.fees[0].extraKms))
            ) +
            (
              res.data[0].price +
              res.data[0].allowance +
              (res.data[0].extraKm * (res.data[0].stdPrice * feesData.fees[0].extraKms))
            ) * feesData.fees[0].gstChauffeur
          ))
        })
        .catch((err) => {
          console.log(err);
        })

    }
    gettingData();
  }, [])

  useEffect(() => {
    axios.get(`${api_url}/payment/success`)
      .then((res) => {
        // console.log(res,'cccccccccccccccc')
        if (res.data.users && res.data.users.length > 0) {
          const lastItem = res.data.users[res.data.users.length - 1];
          setId_booking(lastItem.id+1);
          console.log(lastItem.id)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    async function gettingcoupondata() {
    // console.log(coupon,"function started")
     if(coupon){
      // alert("loading")
     console.log("coupan found")
      if( coupon.code == inputcoupon ) {
        if( coupon.car_type == "Standard"){ 
          if(coupon.discounts_type == "Amount")
          {
            setCouponvalue(coupon.discounts_in_amount)
            coupontext.current.innerHTML = '';
            const span1 = document.createElement('span');
            span1.textContent = 'Discount';
            
            const span2 = document.createElement('span');
            span2.textContent = `${indFormat.format(couponvalue)}`;
            span2.style.color = 'white';

            coupontext.current.appendChild(span1);
            coupontext.current.appendChild(span2);
            
                      
          }
          else if(coupon.discounts_type == "Percentage")
          {
            const mm =   parsedData.price + parsedData.additionalKms_price + parsedData.allowance + ((parsedData.price + parsedData.allowance + parsedData.additionalKms_price) * feesData.fees[0].gstChauffeur)
            setCouponvalue(((mm)/100)*coupon.max_discount_amount)
            coupontext.current.innerHTML = '';
            const span1 = document.createElement('span');
            span1.textContent = 'Discount';
            
            const span2 = document.createElement('span');
            span2.textContent = `${indFormat.format(couponvalue)}`;
            span2.style.color = 'white';
            coupontext.current.appendChild(span1);
            coupontext.current.appendChild(span2);
            
                    } }  }  
      else if (coupon != inputcoupon || coupon != null){ 
        // alert("not found")
        // console.log(coupon,'dddddddddd');
        
        setCouponvalue("")
        coupontext.current.innerHTML = 'Please Enter Valid Coupon '
      }
      else{ couponfound.current.innerHTML =''}  
    }
  }
    gettingcoupondata();
  },)
function demo(){
  try{
    axios.get(`${api_url}/user_information?contact=${phone}`)
          .then((res) => {
              setContact(res.data.user)
              // console.log(res.data.user,"99999999jjjjjjjjjjjjjjj99999999999999")
          })
          .catch((err) => { console.log(err); })
  }
  catch(e){}
  let payload = { "firstname":fName, "lastname":lName, "email":email, "contact":phone };
  // console.log(payload,contactnumber[0],'hhhhhhhhhhhhhhh')
if(!contactnumber[0]){
 
axios
.post(`${api_url}/user_information`, payload)
.then((res) => {
console.log('create-account-details stored');
toast.success('Account has been created',);

})
}

}
function summarysms() {
  let ph=''
  if(phone.includes("+")){
   ph=phone.match(/(\+91)(.{10})/)
  //  axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK! Please find your chauffeur and vehicle details below, Chauffeur name:${chauffeurname} Contact number:${contact} Alternate number:${contact} Vehicle number: ${vehiclenumber} Reporting Time:${reportingtime} cheers Team BLCK&MobileNumbers=${ph[2]}`)
   axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCKLUXURY!%0A%0A Your booking details.%0A%0A Booking id:${id_booking}%0A Customer name:${fName}%0A Contact number:${phone}%0A Email address:${email}%0A Car:${parsedData.name}%0A Package:Standard%0A Reporting time:${parsedData.time}%0A Reporting date:${parsedData.date}%0A Reporting address:${address}%0A City:${localStorage.City}%0A Amount:${parsedData.price + parsedData.additionalKms_price + parsedData.allowance + ((parsedData.price + parsedData.allowance + parsedData.additionalKms_price) * feesData.fees[0].gstChauffeur)}%0A%0A Cheers Blck Luxury&MobileNumbers=91${ph[2]}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)

  //  toast.success('Sms send...')
  }else{
    axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCKLUXURY!%0A%0A Your booking details.%0A%0A Booking id:${id_booking}%0A Customer name:${fName}%0A Contact number:${phone}%0A Email address:${email}%0A Car:${parsedData.name}%0A Package:Standard%0A Reporting time:${parsedData.time}%0A Reporting date:${parsedData.date}%0A Reporting address:${address}%0A City:${localStorage.City}%0A Amount:${parsedData.price + parsedData.additionalKms_price + parsedData.allowance + ((parsedData.price + parsedData.allowance + parsedData.additionalKms_price) * feesData.fees[0].gstChauffeur)}%0A%0A Cheers Blck Luxury&MobileNumbers=91${phone}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)
    // toast.success('Sms send...')
  }


}
  function sendEmail(e) {
    // e.preventDefault();-
    // alert("star")
    const data = {
      user_email: email,
      to_name: fName,
      user_number:phone,
      user_address:address,
      type: 'Standard',
      car_name: parsedData.name,
      car_price: parsedData.price + parsedData.additionalKms_price + parsedData.allowance + ((parsedData.price + parsedData.allowance + parsedData.additionalKms_price) * feesData.fees[0].gstChauffeur),
      booking_stime: parsedData.time,
      booking_sdate: parsedData.date,
      booking_idd: id_booking,
      image_url: encodeURI(parsedData.img),
      location: localStorage.City,
    }
    // console.log(data,"goooot")

    //sms
    summarysms()
    
    const service_id = "service_kg7yi3c";
    const template_id = "template_52lor0n";
    const user_id = "VCFkUIdrxyggthL1g";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    )
      .then(
        (result) => {
          // console.log(result.text);
          // console.log("message sent");
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };
  const submitValue = () => {
    const frmdetails = {
      'First_Name': fName,
      'Last_Name': lName,
      'Phone': phone,
      'Email': email,
      "Address": address
    }
    // console.log(frmdetails);
  }
  const handleAgreeChange = (e) => {
    setIsAgreed(e.target.checked);
  };
  useEffect(() => {
    // console.log(location.parsedData, "parsedData")
    setState(location.state)
  }, []);


  // function sms(){
  //   loadScript("https://smtpjs.com/v3/smtp.js")
  //   alert('hithere')
  //   sendEmail()
  // }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  function alertmsg(frmdetails) {
    const numericValue = frmdetails.Phone.replace(/\D/g, '')
    if (numericValue.length === 10) { validnumber.current.style = "visibility:hidden"; }
    if (frmdetails.Email != "") { validemail.current.style = "visibility:hidden"; }
    if (frmdetails.Address != "") { validaddress.current.style = "visibility:hidden"; }
    if (frmdetails.First_Name != "") { validname.current.style = "visibility:hidden"; }
  }

  async function displayRazorpay() {
if (localStorage.USER_firstname){
  
    const overallprice = Math.round(
   (parsedData.price +
    parsedData.allowance +
    // (parsedData.extraKm * (priceData.stdPrice * feesData.fees[0].extraKms))

    (parsedData.price +
      parsedData.allowance
      // (priceData.extraKm * (priceData.stdPrice * feesData.fees[0].extraKms))
    ) *
    feesData.fees[0].gstChauffeur)-couponvalue)
    
    const frmdetails = {
      'First_Name': fName,
      'Last_Name': lName,
      'Phone': phone,
      'Email': email,
      "Address": address,
      "price": overallprice,
      "location": localStorage.City,
      "GSTno":gstno,
      "package":"standard",
      "GST_company_name":gstname,
    }
    
    if (frmdetails.Email === "" || frmdetails.Phone === "" || frmdetails.Address === ""||frmdetails.First_Name === "") {
      // alert("Please enter the Customer Details! ")
      const numericValue = frmdetails.Phone.replace(/\D/g, ''); // Remove non-digit characters
      
      if (numericValue.length != 10) {validnumber.current.innerHTML = 'Enter valid number!'}
      if (frmdetails.Email === ""){validemail.current.innerHTML = 'Enter email address!' } 
       if (frmdetails.Address === ""){validaddress.current.innerHTML = 'Enter the address!'}
       if (frmdetails.First_Name === ""){validname.current.innerHTML = 'Enter the name!'}
       alertmsg(frmdetails)
     }
       else{
        alertmsg(frmdetails)
      // console.log(frmdetails)
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // creating a new order
      const result = await axios.post(`${api_url}/create_order`, frmdetails);
      // const result = await axios.post(`http://localhost:5001/create_order`, state);


      // console.log(result)
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data;

      const options = {
        // key: "rzp_test_QTMxilX5k0Fv2k", // Enter the Key ID generated from the Dashboard
        key: "rzp_live_GxQjj8KIOaHoso",
        amount: amount.toString(),
        currency: currency,
        name: frmdetails["First_Name"],
        description: "payment",
        // image: { logo },
        order_id: order_id,
        handler: async function (response) {

          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          // console.log(data, "data from handler")
          if (order_id) {
            sendEmail(order_id)
            let data_post =
            {
              user_id: frmdetails.Phone,
              booking_id: id_booking,
              user_details: JSON.stringify(frmdetails),
              razor_orderId: data.razorpayOrderId,
              razor_sign: data.razorpaySignature,
              booking_summary: JSON.stringify(parsedData),
              razor_paymentId: data.razorpayPaymentId,
              payment_status: "Success",
            }
            // console.log(data_post, "datapost")
            //    const result = await axios.post("http://localhost:5001/payment/success", data_post);
            const result = await axios.post(`${api_url}/payment/success`, data_post);
           

          }

          toast.success('Thank you for your booking......')
          const delayMilliseconds = 3000; // Adjust the delay as needed

                 setTimeout(() => {
                   // Navigate to the home page
                   sessionStorage.removeItem("url1");
                   navigate('/Thank_You');
                 }, delayMilliseconds);
        },
        prefill: {
          name: frmdetails["First_Name"] + '' + frmdetails["Last_Name"],
          email: frmdetails["Email"],
          contact: frmdetails["Phone"],
        },
        notes: {
          address: frmdetails["Address"],
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    
    }
  }
  else{
    // localStorage['urll']=url
    sessionStorage.setItem("url1",url)
    navigate("/signup")
  }

  }
  


  // console.log(gstno,gstname);
  // console.log(state, 'state data')
  // console.log("@@@@@@#############", priceData.extraKm);
  // // console.log(priceData.extraKm);
  // console.log(couponvalue,"9000000000000000000000000000000000000000")


  async function ManualBooking(){
    if (sessionStorage.user){
      const overallprice = Math.round(
        parsedData.price +
        parsedData.allowance +
        // (state.extraKm * (priceData.stdPrice * feesData.fees[0].extraKms))
  
        (parsedData.price +
          parsedData.allowance
          // (priceData.extraKm * (priceData.stdPrice * feesData.fees[0].extraKms))
  
        ) *
        feesData.fees[0].gstChauffeur)
  
        const frmdetails = {
          'First_Name': fName,
          'Last_Name': lName,
          'Phone': phone,
          'Email': email,
          "Address": address,
          "price": overallprice,
          "location": localStorage.City,
          "GSTno":gstno,
          "package":"standard",
          "GST_company_name":gstname,
        }
      
      if (frmdetails.Email === "" || frmdetails.Phone === "" || frmdetails.Address === ""||frmdetails.First_Name === "") {
        // alert("Please enter the Customer Details! ")
        const numericValue = frmdetails.Phone.replace(/\D/g, ''); // Remove non-digit characters
        
        if (numericValue.length != 10) {validnumber.current.innerHTML = 'Enter valid number!'}
        if (frmdetails.Email === ""){validemail.current.innerHTML = 'Enter email address!' } 
         if (frmdetails.Address === ""){validaddress.current.innerHTML = 'Enter the address!'}
         if (frmdetails.First_Name === ""){validname.current.innerHTML = 'Enter the name!'}
         alertmsg(frmdetails)
       }else{
       const result = await axios.post(`${api_url}/create_order`, frmdetails);
      // const result = await axios.post(`http://localhost:5001/create_order`, parsedData);
   demo()

      // console.log(result)
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data;
      const data = {
        orderCreationId: "admin-"+phone,
        razorpayPaymentId: "adminpayment-"+phone,
        razorpayOrderId: "adminpay-"+phone,
        razorpaySignature: "admin-paysignature"+phone,
      };

      // const order_id=
      // razorpayOrderId="adminpay-"+phone
      if (order_id) {
        sendEmail(order_id)
        let data_post =
        {
          user_id: frmdetails.Phone,
          booking_id: id_booking,
          user_details: JSON.stringify(frmdetails),
          razor_orderId: data.razorpayOrderId,
          razor_sign: data.razorpaySignature,
          booking_summary: JSON.stringify(parsedData),
          razor_paymentId: data.razorpayPaymentId,
          payment_status: "Success",
        }
        // console.log(data_post, "datapost")
        //    const result = await axios.post("http://localhost:5001/payment/success", data_post);
        const result = await axios.post(`${api_url}/payment/success`, data_post);
        toast.success('Thank you for your booking......')
        const delayMilliseconds = 3000; // Adjust the delay as needed

        setTimeout(() => {
          // Navigate to the home page
          sessionStorage.removeItem("url1");

          navigate('/Thank_You');;
        }, delayMilliseconds);

      }
    }


  }
else{
   
     navigate("/signup")
}
  }



async function cashfree(){
  toast.success('Work in progress, will get back to you soon......')
}


  
  return (
    <main className={s.summaryMain}>
    <Toaster />
      <div className={s.leftSide}>
      <div className={`${s.summaryHeader}`}>
            <span className={`${c.bigText}`}>Summary</span>
            <span className={`${c.smallText}`}>Booking details</span>
          </div>
        <div className={`${s.summarySection}`}>
          
          {loaded ?
            <div className={`${s.carDetailsSection}`}>
              <img src={`${aws_bucket_url}/${parsedData.img}`} alt="carImage" />
              <div>
                <span className={`${c.bigText} ${s.bigText}`}>{parsedData.name}</span>
                <span className={`${c.smallText}`}>Date :{" " + parsedData.startDate}</span>
                <span className={`${c.smallText}`}>Time :{parsedData.startTime}</span>
                <span className={`${c.smallText}`}>Standard - 8 Hr 80Kms</span>
                {/* <span className={`${c.smallText}`}>{parsedData.days}&nbsp;{parsedData.days < 2 ? 'day' : 'days'}</span> */}
              </div>
            </div> : <></>}
        </div>
        <div className={`${s.personalInfoSection}`}>
          <span className={`${c.bigText}`}>Personal Information</span>
          <div className={`${s.infoForm}`}>
            <div className={`${c.split}`}>
              <div>
              <input type="text" name='firstName' placeholder='Full Name' value={fName} onChange={e => setfName(e.target.value)} />
              <div ref={validname} className={`${alert} ${c.secondaryColor}`}></div>
              </div>
              <div>
              <input  type="text" name='mobileNumber' placeholder='Mobile Number' value={phone} onChange={e => setPhone(e.target.value)} />
              <div ref={validnumber} className={`${alert} ${c.secondaryColor}`}></div></div>
            </div>
            <div>
            <input type="text" name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
            <div ref={validemail} className={`${alert} ${c.secondaryColor}`}></div></div>
            <div>
            <textarea name="pickUpAddress" cols="30" rows="3" placeholder='Pick up Address' onChange={e => setAddress(e.target.value)}></textarea>
            <div ref={validaddress} className={`${alert} ${c.secondaryColor}`}></div>
            </div>
            <textarea name="dropOffAddress" cols="30" rows="3" placeholder='Drop Off Address (Optional)'></textarea>
            <textarea style={{ border: '1px solid white' }} name="specialInstructions" cols="30" rows="3" placeholder='Please mention any special instructions'></textarea>
            <div className={`${s.paragraphSection}`}>
              <span className={`${c.tinyText}`}>*   Any cancellation/modification to be informed only via email between 10am to 7pm on all working days and minimum 4 hours prior to the scheduled timer directly</span>
              <span className={`${c.tinyText}`}>*   Any cancellation under 24hrs of the scheduled time will attract 100% charges standard cancellation charges until last 24hrs of scheduled time will be 25%</span>
              <span className={`${c.tinyText}`}>*   Payment Gateway charges are not refunded on any platforms for any type of booking cancellations</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${s.rightSide}`}>
        <div className={`${s.rightSideHeader}`}>
          <span className={`${c.tinyText}`}>Price Breakup</span>
          <span className={`${c.mediumText}`}>Chauffeur Driven</span>
        </div>
        <div className={`${s.priceDetails}`}>
          {<div>
            <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Price </span>
            {loaded ? <span className={`${c.extraSmallText}`}>{indFormat.format(parsedData.price)}</span> : <span className={c.tinyText}>Loading...</span>}
          </div>}
          <div>
            <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Extra Km ({parsedData.kms})</span>
            {loaded ? <span className={`${c.extraSmallText}`}>{
              // indFormat.format
              // (priceData.stdPrice * (priceData.extraKm * feesData.fees[0].extraKms))
              //doubt
              ((parsedData.additionalKms_price))

            }</span> : <span className={c.tinyText}>Loading...</span>}
          </div>
          <div>
            <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>Driver Allowance </span>
            {loaded ? <span className={`${c.extraSmallText}`}>{indFormat.format(parsedData.allowance)}</span> : <span className={c.tinyText}>Loading...</span>}
          </div>
          <div>
            <span className={`${c.extraSmallText}`} style={{ color: 'gray' }}>GST @12%</span>
            {loaded ? <span className={`${c.tinyText}`}>
            {indFormat.format(
              (parsedData.price +
                parsedData.allowance + parsedData.additionalKms_price
                // (priceData.extraKm * (priceData.stdPrice * feesData.fees[0].extraKms))

              ) *
              feesData.fees[0].gstChauffeur
            )}
            </span> : <span className={c.tinyText}>Loading...</span>}
          </div>
          <div ref={coupontext} className={`${c.extraSmallText}`} style={{ color: 'gray' }}></div>

        </div>
        <div className={`${s.totalPrice}`}>

          <div>
            <input id={s.totalPriceInput} type="text" placeholder='Enter Coupon Code'   onChange={e => setInputcoupon(e.target.value) }/>
            <button  className={c.continueBtnFilled} onClick={()=>{
              if(inputcoupon){axios.get(`${api_url}/AddCoupondata?code=${inputcoupon}`)
              .then((res) => {
                  setCoupon(res.data.user[0])
                  // console.log(coupon,"9999999999999999999999")
              })
              .catch((err) => { console.log(err); })}
              else{ couponfound.current.innerHTML = ''
            setCouponvalue("")
            }
// console.log(coupon,"newwwwwwwwwwwwwwwwwwwww")
            }} >Apply</button>
          </div>
          <div className={s.total}>
            <div className={s.coupon01}> </div>
            <span className={c.bigText}>Total&nbsp;</span>
            {loaded ? <span className={`${c.bigText} ${c.secondaryColor}`}>
            {indFormat.format((

              parsedData.price + parsedData.additionalKms_price + parsedData.allowance + ((parsedData.price + parsedData.allowance + parsedData.additionalKms_price) * feesData.fees[0].gstChauffeur)
            )-couponvalue)}
            </span> : <span className={c.extrSmallText}>Loading...</span>}
          </div>
        </div>
        <div className={s.readSection}>
          <span>*   Convenience charges will be added based on mode of payment</span>
          <span>*   Wedding package will attract additional charges</span>
          <span>*   In case of further additional Hours / Kms you can pay your chauffeur directly</span>
          <span>*   GST applicable on all our services, if you have a GSTIN you can quote the same below.</span>
          <span>*   GSTIN 29BAJPA3087M1ZB</span>
        </div>
        <div className={s.payButtonSection}>
          <span className={s.gstSection}>
            <label>
              <input type="checkbox" onChange={(e) => { setGstin(!Gstin) }} />
            </label>
            <div className={c.extraSmallText}>GSTIN</div>
          </span>
          {Gstin ?
            <div>
              <input id={s.totalPriceInput} style={{ height: '40px' }} type="text" name='gstNumber' placeholder='Enter GST Number' onChange={e => setGstno(e.target.value) }/>
              <input id={s.totalPriceInput} style={{ height: '40px' }} type="text" name='companyName' placeholder='Enter Company Name' onChange={e => setGstname(e.target.value) } />
            </div> : <></>}
          <span>
            <label>
              <input type="checkbox" checked={isAgreed}
                onChange={handleAgreeChange} />
            </label>
            <span className={c.extraSmallText}>I agree to the <span className={c.secondaryColor}>Terms & Conditions</span></span>
          </span>
          <div className={`${c.split} ${s.buttons}`}>
          {sessionStorage.user ? (isAgreed ? (
            <button
            className={c.continueBtnFilled}
            onClick={() => ManualBooking()}
            disabled={!isAgreed}
            style={{
              width: "100%",
              backgroundColor: "#DC143C	",
              border: "2px solid transparent",
 borderColor:"#110807",
              color: "var(--text-color)",
              fontWeight: 300,
              height: "40px",
              borderRadius: "0.500rem",
              transition: "all .3s",
            }}
          >
            Book Now
          </button>
        ) : (
          <button
            className={c.continueBtnFilled}
            onClick={() => ManualBooking()}
            disabled={!isAgreed}
            style={{
              width: "100%",
              backgroundColor: "#F08080",
              border: "2px solid transparent",
 borderColor:"#110807",
              color: "var(--text-color)",
              fontWeight: 300,
              height: "40px",
              borderRadius: "0.500rem",
              transition: "all .3s",
            }}
          >
           Book Now
          </button>)
          ): isAgreed ? (
            <button
              className={c.continueBtnFilled}
              onClick={() => displayRazorpay()}
              disabled={!isAgreed}
              style={{
                width: "100%",
                backgroundColor: "#110807",
                border: "2px solid transparent",
 borderColor:"#110807",
                color: "var(--text-color)",
                fontWeight: 300,
                height: "40px",
                borderRadius: "0.500rem",
                transition: "all .3s",
              }}
            >
              Pay Now
            </button>
          ) : (
            <button
              className={c.continueBtnFilled}
              onClick={() => displayRazorpay()}
              disabled={!isAgreed}
              style={{
                width: "100%",
                backgroundColor: "#3D3C3C",
                border: "2px solid transparent",
                borderColor:"#110807",
                color: "var(--text-color)",
                fontWeight: 300,
                height: "40px",
                borderRadius: "0.500rem",
                transition: "all .3s",
              }}
            >
              Pay Now
            </button>
          )}
           {/* <button className={c.continueBtnFilled} onClick={() => sms()}>sms</button> */}
            {/* <button className={c.primaryBtnRed}>Edit</button> */}
          </div>
        </div>
      </div>
    </main>
  )
}
export default SummaryOutstation