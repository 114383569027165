import React,{useEffect, useState, useRef} from 'react';
import s from './partnerwithus.module.css';
import axios from '../../../helpers/Axios';
import c from '../../../assets/css/custom.module.css'

const Partnerwithus = () => {
  useEffect(() => {
    document.title = "Luxury Car Rentals in India | Blck Luxury | Partner With Us";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute("content", "At Blck Luxury, we prioritize your satisfaction and aim to provide the highest level of personalized service.");
    }
}, []);
  return (
    < main className={s.feed_main}>
        <div className={s.feed_header}>
            <span>Be a Luxury partner with Blck!</span>
        </div>
        <div className={s.feed_divide}>
          <div className={`${s.personalInfoSection}`}>
            {/* <div className={`${s.infoForm}`}> */}
            <div className={`${s.split}`}>
              <input type="text" name='firstName' placeholder='First Name' />
              <input type="text" name='lastname' placeholder='Last Name' />
            </div>
            <div className={`${s.split}`}>
              <input type="text" name='firstName' placeholder='Phone Number' />
              <input type="text" name='lastname' placeholder='Email' />
            </div>
            <div className={`${s.split}`}>
              <input type="text" name='firstName' placeholder='Designation' />
              <input type="text" name='lastname' placeholder='City' />
            </div>
            <div className={`${s.split}`}>
              <input type="text" name='firstName' placeholder='Car Model/Variant' />
              <input type="date" name='lastname' placeholder='Year of Car Manufacture' />
            </div>

           
           
           <div className={s.feed_header}>
            <span>Please Select</span>
           </div>
           
           <div className={s.payButtonSection}>
            <div>
              <span>
                <label>
                  <input type="checkbox" />
                </label>
                <span className={c.extraSmallText}>Car not older than 4 years</span>
              </span>
              <span>
                <label>
                  <input type="checkbox" />
                </label>
                <span className={c.extraSmallText}>Comprehensive Insurance</span>
              </span>
              <span>
                <label>
                  <input type="checkbox" />
                </label>
                <span className={c.extraSmallText}>Well maintained</span>
              </span>
              <span>
                <label>
                  <input type="checkbox" />
                </label>
                <span className={c.extraSmallText}>I agree to the <span className={c.secondaryColor}>Terms & Conditions</span></span>
              </span>
            </div>
          </div>
           
            <div className={`${s.uploadSection}`}>
                  <div>
                    {/*<span className={`${c.smallText}`}>Upload Car Images</span>*/}
                    <span className={`${c.smallText} ${c.secondaryColor}`}>Upload Car Images</span>
                  </div>
                  <label className={s.fileUpload}>
                    <input type="file" />
                    <span>
                      <img src={require('../../../assets/images/icons/camera-icon.png')} alt="cameraIcon" width={30} height={30} />
                    </span>
                  </label>
                </div>
                <div className={`${s.uploadSection}`}>
                <div>
                  {/*<span className={`${c.smallText}`}>Upload Car Images</span>*/}
                  <span className={`${c.smallText} ${c.secondaryColor}`}>Upload Car Insurance</span>
                </div>
                <label className={s.fileUpload}>
                  <input type="file" />
                  <span>
                    <img src={require('../../../assets/images/icons/camera-icon.png')} alt="cameraIcon" width={30} height={30} />
                  </span>
                </label>
              </div>
              <div className={`${s.uploadSection}`}>
              <div>
                {/*<span className={`${c.smallText}`}>Upload Car Images</span>*/}
                <span className={`${c.smallText} ${c.secondaryColor}`}>Upload RC Copy of the Car</span>
              </div>
              <label className={s.fileUpload}>
                <input type="file" />
                <span>
                  <img src={require('../../../assets/images/icons/camera-icon.png')} alt="cameraIcon" width={30} height={30} />
                </span>
              </label>
            </div>
           { /*<div className={s.refdivision}>
              <div
                ref={firstNameRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
              <div
                ref={lastNameRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
            </div>*/}
            <textarea name="message" cols="30" rows="5" placeholder='Your Message'></textarea>
            <button className={`${c.primaryBtnFilled} ${s.selectDateBtn}`}>Submit</button>
            {/* </div> */}
          </div>
          </div>
          
    </main>
  )
}

export default Partnerwithus;
